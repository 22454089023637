<template>
  <PageDefault :isLoading="isLoading">
    <template v-slot:content>
      <div class="px-2">
        <div class="my-3">
          <p v-translate class="is-size-4">
            Ha expirado el tiempo para realizar la compra
          </p>
        </div>
        <translate>
          Tu reserva se ha liberado, pero puedes volver a iniciar tu compra, si así lo
          deseas.
        </translate>
        <div>
          <button
            class="button button-2406 is-info mt-4"
            data-cy="link-new-order"
            @click="newOrder"
          >
            <translate>Nuevo pedido</translate>
          </button>
        </div>
      </div>
    </template>
  </PageDefault>
</template>

<script>
import OrderMixin from "@/mixins/OrderMixin"
import Component, { mixins } from "vue-class-component"

@Component
export default class OrderExpired extends mixins(OrderMixin) {
  newOrder() {
    this.$store.dispatch("order/resetEvent").then(() => {
      this.$router.push({
        name: "event",
        params: { event_lookup_ref: this.order.event.lookup_ref },
        query: this.$defaultQuery(),
      })
    })
  }
}
</script>
